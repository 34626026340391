<template>
    <div class="text-base-content" v-allow:auth="'groups.read'" >
        <sub-header :backButton="true" />
        <div class="flex min-w-full flex-1 justify-center items-center py-10" v-if="loading">
            <Loader :loading="loading" />
        </div>
        <div v-else class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar min-h-full">
            <div class="card bg-card-bg rounded-md shadow p-6 flex flex-row gap-5 mb-6 items-center">
                <div class="flex flex-col gap-2 w-5/12">
                    <span class="text-base-content-300 text-md"> Group Name </span>
                    <Input :disabled="!groupsPermission" class="h-11" placeholder="Group Name" bg="white" v-model="groupname" name="groupname"> </Input>
                </div>

                <div class="flex flex-col gap-2 w-6/12">
                    <span class="text-base-content-300 text-md"> Group Description </span>
                    <textarea :disabled="!groupsPermission" class="rounded-lg textarea-bordered border-box-stroke textarea textarea h-8 min-w-full resize-none" placeholder="Group Description" v-model="groupdescription" name="groupdescription"> </textarea>
                </div>
                <div class="flex-1 flex gap-5 w-2/12 items-center">
                    <span class="text-base-content-300 text-md"> </span>
                    <div class="flex mt-7 gap-4">
                        <button :disabled="!groupsPermission" class="h-10 disabled:opacity-50 w-10 flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200" @click="updateGrp">
                            <font-awesome-icon icon="save" class="text-primary text-xl cursor-pointer" />
                        </button>
                        <button :disabled="!checkPermission('groups.delete')" class="h-10 disabled:opacity-50 w-10 bg-gray-100 flex justify-center rounded-full items-center hover:bg-gray-200" @click="deleteGroup">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-red-600 hover:text-red-700 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="relative bg-card-bg rounded-md shadow p-6 flex flex-col gap-5">
                <!-- <div class="flex justify-end">
                    <Button type="primary" text="Add User" class="w-36" @click="showAddUserModal" />
                </div> -->
                <div style="display: flex; justify-content: space-between; align-items: start; height: 100%">
                    <div class="flex gap-6 flex-col" style="flex-grow: 1">
                        <vue-good-table
                            :rows="items"
                            :columns="columns"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                dropdownAllowAll: false
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'actions'" :class="[props.row.system_default && 'default hover-text']" class="flex justify-center gap-3">
                                    <button :disabled="!groupsPermission" @click="groupsPermission && removeUser(props.row.id)" class="h-6 w-6 disabled:opacity-50 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-red-600 hover:text-red-700">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </button>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <!-- delete popup confirm -->

        <modal-confirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup"> </modal-confirm>
    </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import Input from "@/components/input";
// import Button from "@shared/components/button";
import Loader from "@/components/loader";
import { checkPermission } from "@shared/utils/functions"
const modalConfirm = () => import("@/components/modal-confirm");
// import dvTable from "@/components/devliumComponent/field-table/dv-table";
export default {
    name: "field-table-data-view",
    components: {
        // dvTable,
        SubHeader,
        Input,
        // Button,
        modalConfirm,
        Loader,
    },
    props: {},
    data: () => ({
        columns: [
            {
                label: "User Name",
                field: "user_name",
                filterOptions: {
                    enabled: true, // enable filter for this column
                },
            },
            {
                label: "User Email",
                field: "email",
                filterOptions: {
                    enabled: true, // enable filter for this column
                },
            },
            { label: "", field: "actions" },
        ],
        loading: false,
        items: [],
        group_id: "",
        user_id: "",
        user_count: 0,
        groupname: "",
        active: true,
        clients: "",
        groupdescription: "",
        fieldTypeIcon: null,
        error: null,
        loadingState: {
            fetchingUsers: false,
            assignUser: false,
        },
        usersList: [],
        tenant_id: null,
        searchState: {
            userSearch: "",
        },
    }),
    async mounted() {
        this.loading = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenant_id = this.$store.getters.getTenantId;
        this.group_id = this.$route.params?.id;
        await this.getGroupDetails();

        try {
            let groupUsers = await axios.get(`group/user/group_id/${this.group_id}`);
            this.items = groupUsers.data;
            this.user_id = this.items.id;
        } catch (error) {
            console.log("error", error);
        }
        this.loading = false;
    },
    computed:{
        groupsPermission(){
            return this.checkPermission('groups.edit')
        }
    },
    methods: {
        checkPermission,
        async removeUser(id) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This user  will be deleted permanently. Please confirm to continue deleting this user.",
            });
            if (promise) {
                try {
                    let { data } = await axios.delete(`group/group_id/${this.group_id}/user/${id}`);
                    this.$toast.success(data.message || "user deleted");
                    const finalData = this.items.filter((x) => x.id !== id);
                    this.items = finalData;
                    // location.reload();
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to remove user");
                    console.log("error", error);
                }
            }
            this.$refs["confirm-popup"].close();

        },
        async getGroupDetails() {
            let url = `group/tenant/${this.$store.getters.getTenantId}/group/${this.group_id}`;
            try {
                let { data } = await axios.get(url);
                this.groupname = data.title;
                this.groupdescription = data.description;
            } catch (error) {
                console.log(error, "Error");
            }
        },
        async deleteGroup() {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This group will be deleted permanently. Please confirm to continue deleting this group.",
            });
            if (promise) {
                try {
                    let { data } = await axios.delete(`group/tenant/${this.$store.getters.getTenantId}/group_id/${this.group_id}`);
                    this.$toast.success(data.message || "Group Deleted");
                    this.$router.back();
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to delete group");
                }
            }
            this.$refs["confirm-popup"].close();
        },
        async updateGrp() {
            let updatedGroupName = '' 
            if(this.groupname.trim() === '') {
                this.$toast.error("Please fill the group name!");
                return
            }
            else updatedGroupName = this.groupname.replace(/\s+/g, ' ')
            
            try {
                let { data } = await axios.put(`group/tenant/${this.group_id}`, {
                    title: updatedGroupName,
                    description: this.groupdescription,
                    active: this.active,
                    tenant_id: this.$store.getters.getTenantId,
                });
                this.$toast.success(data.message || "Group updated");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to delete group");
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
